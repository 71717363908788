import React, { useEffect } from 'react';
import { isSafari } from 'react-device-detect';
import Layout from '../components/layout';
import TheProblemSection from '../components/the-problem-section';
import CoverSection from '../components/cover-section';
import IntroSection from '../components/intro-section';
import WhyPeersForPeersSection from '../components/why-peers-for-peers-section';
import HowItWorksSection from '../components/how-it-works-section';
import AboutUsSection from '../components/about-us-section';
import LoadingScreen from '../components/loading-screen';

const IndexPage = () => {
    const [state, setState] = React.useState({
        variables: { loading: true }
    });

    const p4pAppIframe = React.useRef(null);
    const messageIntervalId = React.useRef(null);

    const handleMessageEvent = (event) => {
        if (event.origin !== process.env.GATSBY_P4P_APP_URL) {
            return;
        }
        if (event.data) {
            window.location.href = process.env.GATSBY_P4P_APP_URL;
        } else {
            setState({ variables: { loading: false } });
        }
        window.removeEventListener('message', handleMessageEvent);
        clearInterval(messageIntervalId.current);
    };

    // This runs when the page is loaded.
    useEffect(() => {
        if (process.env.GATSBY_ENVIRONMENT === 'development' || isSafari) {
            setState({ variables: { loading: false } });
            return;
        }

        window.addEventListener('message', handleMessageEvent);

        messageIntervalId.current = setInterval(() => {
            if (p4pAppIframe?.current?.contentWindow) {
                p4pAppIframe.current.contentWindow.postMessage(
                    '',
                    process.env.GATSBY_P4P_APP_URL
                );
            }
        }, 100);

        return () => {
            clearInterval(messageIntervalId.current);
            window.removeEventListener('message', handleMessageEvent);
        };
    }, []);

    return (
        <>
            <iframe
                ref={p4pAppIframe}
                src={`${process.env.GATSBY_P4P_APP_URL}`}
                title="Peers for Peers App"
                className="hidden-p4p-app-iframe"
            ></iframe>
            {state.variables.loading ? (
                <LoadingScreen></LoadingScreen>
            ) : (
                <Layout pageTitle="The easiest way to stay current in neurosurgery.">
                    <CoverSection></CoverSection>
                    <IntroSection></IntroSection>
                    <WhyPeersForPeersSection></WhyPeersForPeersSection>
                    <TheProblemSection></TheProblemSection>
                    <HowItWorksSection></HowItWorksSection>
                    <AboutUsSection></AboutUsSection>
                </Layout>
            )}
        </>
    );
};

export default IndexPage;
